<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <v-card flat>
        <v-card-title class="pa-0">            
          <v-tabs 
            v-model="selectTab" 
            background-color="grey lighten-5"
            color="secondary" 
            show-arrows 
            active-class="blue-grey lighten-5"
          >
            <v-tab
              v-for="(tab,i) in items"
              :key="'tabestadisticas-'+i"
            >
              <v-icon
                size="16"
                class="mr-1"
                v-text="tab.icon"
              />
              <span
                class="hidden-xs-only text-capitalize"
                v-text="tab.name"
              />
            </v-tab>                                
          </v-tabs>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card>
        <v-card-text>
          <v-tabs-items v-model="selectTab" touchless>
            <v-tab-item v-if="$hasPermission('ruta-vendedores.visitas-programadas.consulta')">
              <cuentas-por-cobrar :ven="{nombre: vendedor.ven_des, cod: vendedor.co_ven}" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'Estadisticas',
  components: {
    CuentasPorCobrar: () => import (
      /* webpackChuckName: "cuentas-por-cobrar" */
      './CuentasPorCobrar.vue'
    )
  },
  props: {
    vendedor: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    return {
      selectTab: 0,
      items: [
        {
          name: 'Cuentas por Cobrar',
          icon: 'mdi-file-alert-outline', 
          permiso: 'ruta-vendedores.consulta',   
        },
      ],
    }
  }

}
</script>